import useBreakpoint from "use-breakpoint";

interface UseBreakpointValueProps<T = string> {
  base?: T;
  sm?: T;
  md?: T;
  lg?: T;
  xl?: T;
  xxl?: T;
}

const BREAKPOINTS = { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200, xxl: 1400 };

export const useBreakpointValue = <T>(
  props: UseBreakpointValueProps<T>
): T | undefined => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const { base, sm, md, lg, xl, xxl } = props;

  switch (breakpoint) {
    case "xs":
      return base;
    case "sm":
      return sm ?? base;
    case "md":
      return md ?? sm ?? base;
    case "lg":
      return lg ?? md ?? sm ?? base;
    case "xl":
      return xl ?? lg ?? md ?? sm ?? base;
    case "xxl":
      return xxl ?? xl ?? lg ?? md ?? sm ?? base;
  }
};
