import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { UseQueryResult } from 'react-query';
import { FoodType } from '@proveg/dtos';
import { ProvegFormState } from '../interface/ProvegFormState';

const useAvailableItemDetail = (query: UseQueryResult<string[]>, foodType: FoodType) => {
  const { setFieldValue, values } = useFormikContext<ProvegFormState>();

  useEffect(() => {
    if (query.isSuccess && !query.data?.includes(values.item_detail ?? '')) {
      const generalOrDefaultItemDetail =
        query.data.find((data) => data.includes('general')) ?? query.data[0];

      setFieldValue('item_detail', generalOrDefaultItemDetail);
    }
  }, [setFieldValue, query, values.item_detail, foodType]);
};

export default useAvailableItemDetail;
