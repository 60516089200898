import React, { FC } from 'react';
import classNames from 'classnames';
import { Col, Container, Row } from 'react-bootstrap';
import { useBreakpointValue } from '../../hooks/useBreakpointValue';
import { RadioButton } from '../inputs/RadioButton';
import { Impact } from '@proveg/dtos';

interface ResultSectionProps {
  hideBorder?: boolean;
}

export const ResultSection: FC<ResultSectionProps> = ({ children, hideBorder }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Container className="w-100 mt-4 mt-sm-5">
      {/* {isMobile && <label className="mb-3">Choose the impact on</label>} */}
      <Row className={classNames(isMobile && !hideBorder && 'mb-5' && 'd-none')}>
        <Col className="p-sm-0 d-flex">
          <RadioButton value={Impact.ANIMALS} name="impact_on" className="flex-grow-1">
            Animals
          </RadioButton>
          {/* <RadioButton value={Impact.ENVIRONMENT} name="impact_on" className="flex-grow-1">
            Environment
          </RadioButton> */}
        </Col>
      </Row>
      <Row
        className={classNames(
          'border border-bordergreen',
          !hideBorder && 'p-4 py-md-6',
          'px-md-5',
          !isMobile && 'border-top-0',
          hideBorder && 'border-0'
        )}
      >
        {children}
      </Row>
    </Container>
  );
};
