import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import { Dropdown } from '../../inputs/Dropdown';
import useAxiosQuery from '../../../hooks/useAxiosQuery';
import { ProvegFormState } from '../../../interface/ProvegFormState';

export const AreaDropdown: FC = () => {
  const { values } = useFormikContext<ProvegFormState>();

  const { data, isLoading } = useAxiosQuery<string[]>('areas', {
    params: { continent: values.un_continent, subregion: values.un_sub_region_name },
  });

  return (
    <Dropdown
      name="area"
      placeholder="Select country..."
      isDisabled={values.un_continent === 'World'}
      className="me-md-5 my-3 my-sm-2 w-100 w-lg-auto font-condensed"
      options={data?.map((d) => ({ label: d, value: d })) ?? []}
      isLoading={isLoading}
      isClearable
      isSearchable
    />
  );
};
