import React, { FC, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { useImpactCalculation } from 'apps/calculator/src/providers/CalculationProvider';
import { NumberValueInput } from '../../inputs/NumberValueInput';
import { ResultSection } from '../../sections/ResultSection';
import { Cow } from '../../svg/Cow';
import { useFormikContext } from 'formik';

export const MilkIngredientCalculator: FC = () => {
  const calculatedImpact = useImpactCalculation();

  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue('item_detail', '');
  }, [setFieldValue]);

  return (
    <ResultSection>
      <Col className="col-12 col-md-5">
        <NumberValueInput
          name="amount"
          label="How many kilograms of Milk do you want to replace?"
        />
      </Col>
      <Col className="col-12 col-md-7 d-flex flex-column">
        <label className="fw-bold mb-3 ">The quantity of milk replaced avoids the use of</label>

        <div className="d-sm-flex justify-content-evenly align-items-center mt-sm-3 flex-grow-1">
          <div className="d-flex justify-content-center">
            <Cow text={calculatedImpact.value} />
          </div>
          <label className="fw-bold">bovine animals</label>
        </div>
      </Col>
    </ResultSection>
  );
};
