import React, { FC } from 'react';
import Select, { GroupTypeBase, Styles } from 'react-select';
import classNames from 'classnames';
import { useField } from 'formik';

type SelectOption = {
  value: string;
  label: string;
};

interface DropdownProps {
  name: string;
  options: SelectOption[];
  className?: string;
  label?: string;
  boldLabel?: boolean;
  placeholder?: string;
  isLoading?: boolean;
  isClearable?: boolean;
  isDisabled?: boolean;
  isSearchable?: boolean;
}

type SelectStyles = Partial<
  Styles<
    {
      value: string;
      label: string;
    },
    false,
    GroupTypeBase<{
      value: string;
      label: string;
    }>
  >
>;

const customStyles: SelectStyles = {
  option: (provided, state) => ({
    ...provided,
    background: state.isSelected ? '#147c3f' : undefined,
    color: state.isSelected ? 'white' : undefined,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    background: '#147c3f',
    height: '100%',
    display: 'flex',
    width: 45,
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    ':hover': {
      color: 'lightgray',
    },
  }),
  indicatorSeparator: (provided) => ({ ...provided, display: 'none' }),
  loadingIndicator: (provided) => ({ ...provided, width: 45, margin: 0 }),
  clearIndicator: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    height: 45,
    width: 90,
    border: 'none',
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: 45,
    paddingLeft: 15,
  }),
};

export const Dropdown: FC<DropdownProps> = ({
  className,
  options,
  name,
  label,
  boldLabel,
  placeholder,
  isLoading,
  isClearable,
  isDisabled,
  isSearchable,
}) => {
  const [field, , helpers] = useField(name);
  return (
    <div className={className}>
      {field && label && (
        <label className={classNames('mb-3 font-condensed', boldLabel && 'fw-bold')}>{label}</label>
      )}
      <Select
        {...field}
        isDisabled={isDisabled}
        isSearchable={!!isSearchable}
        isClearable={isClearable}
        isLoading={isLoading}
        options={options}
        onChange={(option: SelectOption | null, meta) => {
          if (option) {
            helpers.setValue(option.value);
          }
          if (meta.action === 'clear') {
            helpers.setValue('');
          }
        }}
        value={field.value && { value: field.value, label: field.value }}
        clearValue={() => helpers.setValue('')}
        className="shadow-sm fs-6 font-condensed"
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary: '#147c3f',
            primary25: '#bee0c6',
            primary50: '#bee0c6',
          },
        })}
        placeholder={placeholder}
        styles={customStyles}
      />
    </div>
  );
};
