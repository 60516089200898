import React, { FC } from 'react';
import { Col } from 'react-bootstrap';
import { FoodType } from '@proveg/dtos';
import useAvailableItemDetail from 'apps/calculator/src/hooks/useAvailableItemDetail';
import { useImpactCalculation } from 'apps/calculator/src/providers/CalculationProvider';
import useAxiosQuery from 'apps/calculator/src/hooks/useAxiosQuery';
import { NumberValueInput } from '../../inputs/NumberValueInput';
import { ResultSection } from '../../sections/ResultSection';
import { Chicken } from '../../svg/Chicken';
import { Dropdown } from '../../inputs/Dropdown';

export const EggsIngredientCalculator: FC = () => {
  const subcategoriesQuery = useAxiosQuery<string[]>('item-details', {
    params: { item: FoodType.EGGS },
  });

  useAvailableItemDetail(subcategoriesQuery, FoodType.EGGS);

  const calculatedImpact = useImpactCalculation();

  return (
    <ResultSection>
      <Col className="col-12 col-md-5">
        <NumberValueInput
          name="amount"
          label="How many kilograms of eggs do you want to replace?"
        />
        <Dropdown
          name="item_detail"
          className="mb-4 mb-sm-5"
          label="Please choose subcategory (optional)"
          options={subcategoriesQuery.data?.map((d) => ({ value: d, label: d })) ?? []}
          isLoading={subcategoriesQuery.isLoading}
        />
        <label className="mb-4 mb-sm-0">
          Please note: when combining different egg components results may be less accurate, since
          each egg component will be calculated as coming from a new egg.
        </label>
      </Col>

      <Col className="col-12 col-md-7 d-flex flex-column">
        <label className="fw-bold mb-3">The number of eggs replaced avoids the use of</label>

        <div className="d-sm-flex justify-content-evenly align-items-center mt-sm-3 flex-grow-1">
          <div className="d-flex justify-content-center">
            <Chicken text={calculatedImpact.value} />
          </div>
          <label className="fw-bold">poultry animals</label>
        </div>
      </Col>
    </ResultSection>
  );
};
