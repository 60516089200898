import React, { FC, useEffect } from 'react';
import { useFormikContext } from 'formik';
import useAxiosQuery from '../../../hooks/useAxiosQuery';
import { ProvegFormState } from '../../../interface/ProvegFormState';
import { Dropdown } from '../../inputs/Dropdown';

export const ContinentDropdown: FC = () => {
  const { data, isLoading } = useAxiosQuery<string[]>('continents');
  const { values, setFieldValue } = useFormikContext<ProvegFormState>();

  useEffect(() => {
    if (data?.length && !values.un_continent) {
      setFieldValue('un_continent', data[0]);
    }
  }, [values.un_continent, setFieldValue, data]);

  return (
    <Dropdown
      name="un_continent"
      placeholder="Select continent..."
      className="me-md-5 my-3 my-sm-2 w-100 w-lg-auto font-condensed"
      options={data?.map((d: string) => ({ label: d, value: d })) ?? []}
      isLoading={isLoading}
      isSearchable
    />
  );
};
