import React from 'react';
import { Container } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import { CalculatorType, FoodType, Impact } from '@proveg/dtos';

import { CalculatorTypeSection } from './sections/CalculatorTypeSection';
import { LocationSection } from './sections/LocationSection/LocationSection';
import { FoodTypeSection } from './sections/FoodTypeSection';
import { CalculatorController } from './calculators/CalculatorController';

import { ProvegFormState } from '../interface/ProvegFormState';

import { CalculationProvider } from '../providers/CalculationProvider';
import { ResultBreakdownSection } from './sections/ResultBreakdownSection';
import { Footer } from './layout/Footer';

export const Calculator = () => (
  <Container className="pb-4 justify-content-center">
    <Formik<ProvegFormState>
      initialValues={{
        calculator: CalculatorType.MEALS,
        item: FoodType.MEAT,
        area: '',
        un_sub_region_name: '',
        un_continent: '',
        impact_on: Impact.ANIMALS,
        item_detail: '',
      }}
      onSubmit={() => {}}
    >
      {({ values, setFieldValue }) => (
        <CalculationProvider values={values} setFieldValue={setFieldValue}>
          <Form>
            <CalculatorTypeSection />
            <LocationSection />
            <FoodTypeSection calculatorType={values.calculator} />
            <CalculatorController calculatorType={values.calculator} foodType={values.item} />
            {values.calculator === CalculatorType.DIET && values.impact_on === Impact.ANIMALS && (
              <ResultBreakdownSection />
            )}
          </Form>
        </CalculationProvider>
      )}
    </Formik>
    <Footer />
  </Container>
);
