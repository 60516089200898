import React, { FC, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Col } from 'react-bootstrap';
import { useImpactCalculation } from 'apps/calculator/src/providers/CalculationProvider';
import { NumberValueInput } from '../../inputs/NumberValueInput';
import { ResultSection } from '../../sections/ResultSection';
import { AnimalGroup } from '../../svg/AnimalGroup';

export const DietCalculator: FC = () => {
  const calculatedImpact = useImpactCalculation();

  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue('item_detail', '');
  }, [setFieldValue]);

  return (
    <ResultSection>
      <Col className="col-12 col-lg-5">
        <NumberValueInput
          name="secondaryAmount"
          placeholder="Months"
          label="How long have you been following a plant-based diet"
        />

        <NumberValueInput name="amount" placeholder="Years" />
      </Col>

      <Col className="col-12 col-md-7 d-flex flex-column">
        <label className="fw-bold mb-3 ">You have avoided the consumption and use of</label>

        <div className="d-sm-flex justify-content-evenly align-items-center mt-sm-3 flex-grow-1">
          <div className="d-flex justify-content-center">
            <AnimalGroup text={calculatedImpact.value} />
          </div>
          <label className="mt-4 fw-bold">animals</label>
        </div>
      </Col>
    </ResultSection>
  );
};
