import { IsNotEmpty, IsNumber, IsObject, IsOptional } from 'class-validator';

export class CalculatorResult {
  @IsNumber()
  @IsNotEmpty()
  value: number;
  @IsNumber()
  @IsNotEmpty()
  savedEmission: number;
  @IsObject()
  @IsOptional()
  breakdownDietResult?: {
    cowsForMeat: number;
    cowsForMilk: number;
    poultryForMeat: number;
    eggLayers: number;
    pigsForMeat: number;
  };
}
