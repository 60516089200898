import React, { FC } from 'react';
import { Form } from 'react-bootstrap';
import classNames from 'classnames';
import { useField } from 'formik';

interface NumberValueInputProps {
  name: string;
  label?: string;
  className?: string;
  placeholder?: string;
}

export const NumberValueInput: FC<NumberValueInputProps> = ({
  name,
  label,
  className,
  placeholder = 'Enter a value...',
}) => {
  const [field] = useField(name);
  return (
    <div className={classNames('mb-4', className)}>
      {label && <label className="mb-3 font-condensed">{label}</label>}
      <Form.Control
        {...field}
        type="number"
        className="p-text-input rounded-0 shadow-sm"
        placeholder={placeholder}
      />
    </div>
  );
};
