import React, { FC } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Section } from '../layout/Section';
import { Cow } from '../svg/Cow';
import { Chicken } from '../svg/Chicken';
import { Pig } from '../svg/Pig';
import { useBreakpointValue } from '../../hooks/useBreakpointValue';
import { useImpactCalculation } from '../../providers/CalculationProvider';

export const ResultBreakdownSection: FC = () => {
  const animalClassname =
    'd-flex col-12 col-md-2 flex-column justify-content-center align-items-center mb-5 mb-md-0 h-100';
  const rowHeight = useBreakpointValue({ md: '12rem' });

  const { breakdownResult } = useImpactCalculation();

  const getStringResult = (val?: number) =>
    val?.toLocaleString(undefined, {
      maximumFractionDigits: 1,
    });

  return (
    <Section>
      <Row className="justify-content-between" style={{ height: rowHeight }}>
        <Col className={animalClassname}>
          <Cow size="sm" text={getStringResult(breakdownResult?.cowsForMeat)} />
          cows for meat
        </Col>
        <Col className={animalClassname}>
          <Cow size="sm" text={getStringResult(breakdownResult?.cowsForMilk)} />
          <span>cows for milk</span>
        </Col>
        <Col className={animalClassname}>
          <Chicken size="sm" text={getStringResult(breakdownResult?.poultryForMeat)} />
          birds for meat
        </Col>
        <Col className={animalClassname}>
          <Chicken size="sm" text={getStringResult(breakdownResult?.eggLayers)} />
          egg layers
        </Col>
        <Col className={animalClassname}>
          <Pig size="sm" text={getStringResult(breakdownResult?.pigsForMeat)} />
          pigs for meat
        </Col>
      </Row>
    </Section>
  );
};
