import React, { FC } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { ProvegLogo } from '../svg/ProvegLogo';
import { useBreakpointValue } from '../../hooks/useBreakpointValue';

export const Header: FC = () => {
  const fontSize = useBreakpointValue({
    base: '1.5rem',
    sm: '2rem',
    md: '2.5rem',
    xxl: '3rem',
  });
  return (
    <Container style={{ height: 100 }} fluid className="bg-primary d-flex align-items-center px-4">
      <Row className="flex-grow-1">
        <Col className="col-3 d-flex align-items-center ">
          <ProvegLogo />
        </Col>
        <Col className="col-6 d-flex align-items-center">
          <h1
            style={{ fontSize }}
            className="text-white my-0 mx-auto text-center user-select-none font-extra-condensed"
          >
            Impact Calculator
          </h1>
        </Col>
        <Col className="col-3"></Col>
      </Row>
    </Container>
  );
};
