import React, { FC } from 'react';
import { Dropdown } from '../../inputs/Dropdown';
import { useFormikContext } from 'formik';
import useAxiosQuery from '../../../hooks/useAxiosQuery';
import { ProvegFormState } from '../../../interface/ProvegFormState';

export const SubregionDropdown: FC = () => {
  const { values } = useFormikContext<ProvegFormState>();
  const { data, isLoading } = useAxiosQuery<string[]>('subregions', {
    params: { continent: values.un_continent },
  });

  return (
    <Dropdown
      name="un_sub_region_name"
      isDisabled={values.un_continent === 'World'}
      placeholder="Select subregion..."
      className="me-md-5 my-3 my-sm-2 w-100 w-lg-auto font-condensed"
      options={data?.map((d) => ({ label: d, value: d })) ?? []}
      isLoading={isLoading}
      isClearable
      isSearchable
    />
  );
};
