import { useState } from 'react';

export const useAlert = () => {
  const [message, setMessage] = useState<string>();
  const [variant, setVariant] = useState<string>();

  const activateAlert = (message: string, variant: string) => {
    setMessage(message);
    setVariant(variant);
  };

  const closeAlert = () => {
    setMessage(undefined);
    setVariant(undefined);
  };

  return { message, variant, activateAlert, closeAlert };
};
