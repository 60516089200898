import { IsNotEmpty, IsString } from 'class-validator';

export class DataUploadDTO {
  @IsString()
  @IsNotEmpty()
  username: string;
  @IsString()
  @IsNotEmpty()
  password: string;

  file?: File;
}
