import { CalculatorResult, FoodType, Impact } from '@proveg/dtos';
import axios from 'axios';
import debounce from 'lodash.debounce';
import React, { FC, useState, useMemo, useEffect, useContext } from 'react';
import { usePrevious } from '../hooks/usePrevious';
import { ProvegFormState } from '../interface/ProvegFormState';

const CalculationContext = React.createContext<{
  value: string;
  breakdownResult?: CalculatorResult['breakdownDietResult'];
}>({ value: '' });

export const useImpactCalculation = () => useContext(CalculationContext);

// utility functions to query by more precise value than displayed in the calculator
const translateItemValue = (value: string) => {
  if (value === FoodType.MILK) {
    return 'Milk from cows';
  }
  return value;
};

export const CalculationProvider: FC<{
  values: ProvegFormState;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}> = ({ children, values, setFieldValue }) => {
  const [impactValues, setImpactValue] = useState<CalculatorResult>({ value: 0, savedEmission: 0 });

  const prevContinent = usePrevious(values.un_continent);
  const prevSubregion = usePrevious(values.un_sub_region_name);

  const calculateImpact = useMemo(
    () =>
      debounce(async () => {
        const { data } = await axios.get<CalculatorResult>(`/calculators/${values.calculator}`, {
          params: {
            ...values,
            item: translateItemValue(values.item),
            amount: values.amount || 0,
            secondaryAmount: values.secondaryAmount || 0,
          },
        });
        setImpactValue(data);
      }, 300),
    [values]
  );

  useEffect(() => {
    calculateImpact();
    return () => {
      calculateImpact.cancel();
    };
  }, [calculateImpact]);

  useEffect(() => {
    if (values.item === FoodType.MEAT) {
      setFieldValue('item_detail', '');
    }
  }, [values.item, setFieldValue]);

  useEffect(() => {
    if (prevContinent !== values.un_continent) {
      setFieldValue('un_sub_region_name', '');
      setFieldValue('area', '');

    }
  }, [prevContinent, values.un_continent, setFieldValue]);

  useEffect(() => {
    if (prevSubregion !== values.un_sub_region_name) {
      setFieldValue('area', '');
    }
  }, [prevSubregion, values.un_sub_region_name, setFieldValue]);

  const value =
    values.impact_on === Impact.ANIMALS ? impactValues.value : impactValues.savedEmission;

  return (
    <CalculationContext.Provider
      value={{
        value: value.toLocaleString('en-US', {
          maximumFractionDigits: 1,
        }),
        breakdownResult: impactValues.breakdownDietResult,
      }}
    >
      {children}
    </CalculationContext.Provider>
  );
};
