import React, { FC } from 'react';
import { Col } from 'react-bootstrap';
import { FoodType } from '@proveg/dtos';

import useAxiosQuery from 'apps/calculator/src/hooks/useAxiosQuery';
import { Dropdown } from '../../inputs/Dropdown';
import { NumberValueInput } from '../../inputs/NumberValueInput';
import { ResultSection } from '../../sections/ResultSection';
import { Chicken } from '../../svg/Chicken';
import useAvailableItemDetail from 'apps/calculator/src/hooks/useAvailableItemDetail';
import { useImpactCalculation } from 'apps/calculator/src/providers/CalculationProvider';

export const PoultryMeatIngredientCalculator: FC = () => {
  const subcategoriesQuery = useAxiosQuery<string[]>('item-details', {
    params: { item: FoodType.POULTRY_MEAT },
  });

  useAvailableItemDetail(subcategoriesQuery, FoodType.POULTRY_MEAT);

  const calculatedImpact = useImpactCalculation();

  return (
    <ResultSection>
      <Col className="col-12 col-md-5">
        <NumberValueInput
          name="amount"
          label="How many kilograms of meat do you want to replace?"
        />
        <Dropdown
          name="item_detail"
          className="mb-4"
          label="Please choose a subcategory (optional)"
          options={subcategoriesQuery.data?.map((d) => ({ value: d, label: d })) ?? []}
          isLoading={subcategoriesQuery.isLoading}
        />
        <label className="mb-4 mb-sm-0">
          Please note: when combining different cuts from the same animal type results may be less
          accurate, since each cut will be calculated as coming from a new animal
        </label>
      </Col>
      <Col className="col-12 col-md-7 d-flex flex-column">
        <label className="fw-bold mb-3">
          The quantity of meat replaced is equivalent to consuming
        </label>

        <div className="d-sm-flex justify-content-evenly align-items-center mt-sm-3 flex-grow-1">
          <div className="d-flex justify-content-center">
            <Chicken text={calculatedImpact.value} />
          </div>
          <label className="fw-bold">poultry animals</label>
        </div>
      </Col>
    </ResultSection>
  );
};
