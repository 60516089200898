import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Calculator } from './components/Calculator';
import { DataUploader } from './components/DataUploader';
import { Header } from './components/layout/Header';

const App = () => {
  const dataUploadRoute = process.env.REACT_APP_DATA_UPLOAD_ROUTE;
  return (
    <Router>
      <Header />
      <Switch>
        {dataUploadRoute && (
          <Route path={`/${dataUploadRoute}`}>
            <DataUploader />
          </Route>
        )}
        <Route path="/">
          <Calculator />
        </Route>
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
