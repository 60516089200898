import { FC } from 'react';

export const Footer: FC = () => (
  <div className="mx-2 my-3 d-flex justify-content-between text-primary user-select-none">
    <a href="https://proveg.org/how-we-calculate-animal-savings-through-replacing-ingredients-or-meals/" target="_blank" rel="noreferrer">
      Methodology
    </a>
    <a href="https://proveg.org/privacy-policy/" target="_blank" rel="noreferrer">
      Privacy policy
    </a>
    <a href="https://proveg.org/legal-disclosure/" target="_blank" rel="noreferrer">
      Legal disclosure
    </a>
  </div>
);
