import axios from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

const useAxiosQuery = <T>(
  url: string,
  config?: {
    params?: Record<string, string | undefined>;
    additionalDeps?: any[];
    queryOptions?: UseQueryOptions<T, unknown, T, any[]>;
  }
) =>
  useQuery(
    [url, config?.params, ...(config?.additionalDeps ?? [])],
    async () => {
      const response = await axios.get<T>(url, { params: config?.params });
      return response.data;
    },
    config?.queryOptions
  );

export default useAxiosQuery;
