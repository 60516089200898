import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { CalculatorType } from '@proveg/dtos';
import { Section } from '../../layout/Section';
import { AreaDropdown } from './AreaDropdown';
import { ContinentDropdown } from './ContinentDropdown';
import { SubregionDropdown } from './SubregionDropdown';
import { ProvegFormState } from '../../../interface/ProvegFormState';

const getDescription = (type: CalculatorType) => {
  switch (type) {
    case CalculatorType.DIET:
      return 'This refers to the region where you live.';
    case CalculatorType.INGREDIENTS:
    case CalculatorType.MEALS:
    default:
      return 'This refers to the region where the meals or ingredients have been produced.';
  }
};

export const LocationSection = () => {
  const formContext = useFormikContext<ProvegFormState>();

  const description = getDescription(formContext.values.calculator);
  return (
    <Section>
      <Row>
        <Col className="font-condensed">Please choose your region</Col>
      </Row>
      <Row className="py-3">
        <Col className="col-12 col-lg-4">
          <ContinentDropdown />
        </Col>
        <Col className="col-12 col-lg-4">
          <SubregionDropdown />
        </Col>
        <Col className="col-12 col-lg-4">
          <AreaDropdown />
        </Col>
      </Row>
      <Row>
        <Col className="me-md-5 my-3 mb-sm-0">{description}</Col>
      </Row>
    </Section>
  );
};
