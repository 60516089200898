import { IsString, IsNumber, IsNotEmpty, IsOptional } from 'class-validator';
import { Transform } from 'class-transformer';
import { FoodType } from '../enums/food-type.enum';

export class CalculationDTO {
  @IsNotEmpty()
  @IsString()
  item: FoodType;

  @IsNotEmpty()
  @IsString()
  un_continent: string;

  @IsNotEmpty()
  @IsNumber()
  @Transform(({ value }) => parseFloat(value))
  amount: number;

  @IsOptional()
  @IsNumber()
  @Transform(({ value }) => parseFloat(value))
  secondaryAmount?: number;

  @IsOptional()
  @IsString()
  @Transform(({ value }) => value || undefined)
  area?: string;

  @IsOptional()
  @IsString()
  @Transform(({ value }) => value || undefined)
  un_sub_region_name?: string;

  @IsOptional()
  @IsString()
  @Transform(({ value }) => value || undefined)
  item_detail?: string;
}
