import React, { FC } from 'react';

export const ProvegLogo: FC = () => (
  <svg
    width="176"
    height="45"
    viewBox="0 0 176 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M48.2243 18.2126C48.2243 20.5688 49.0565 22.5847 50.7218 24.2599C52.3869 25.9355 54.3902 26.7731 56.7317 26.7731C59.0733 26.7731 61.0764 25.9355 62.7416 24.2599C64.3936 22.5978 65.2198 20.582 65.2198 18.2126C65.2198 15.8437 64.3936 13.828 62.7416 12.1656C61.0764 10.4902 59.0733 9.65222 56.7317 9.65222C54.3902 9.65222 52.3869 10.4902 50.7218 12.1656C49.0565 13.8411 48.2243 15.8567 48.2243 18.2126ZM48.2243 37.4148C48.2243 37.7549 48.1072 38.0431 47.873 38.2786C47.6388 38.5142 47.3526 38.6321 47.0144 38.6321C46.676 38.6321 46.39 38.5142 46.1558 38.2786C45.9217 38.0431 45.8047 37.7549 45.8047 37.4148V18.2126C45.8047 15.1762 46.8712 12.5845 49.0048 10.4376C51.138 8.29115 53.7138 7.2177 56.7317 7.2177C59.7496 7.2177 62.3253 8.29115 64.4588 10.4376C66.5921 12.5845 67.6589 15.1762 67.6589 18.2126C67.6589 21.2495 66.5921 23.8413 64.4588 25.9877C62.3253 28.1346 59.7496 29.2077 56.7317 29.2077C53.7138 29.2077 51.138 28.1346 49.0048 25.9877L48.2243 25.2221V37.4148Z"
      fill="white"
    />
    <path
      d="M69.6079 8.13689C69.6079 7.79666 69.7251 7.50859 69.9592 7.27298C70.1934 7.03736 70.4794 6.91963 70.8178 6.91963C71.1559 6.91963 71.4422 7.03736 71.6764 7.27298C71.9105 7.50859 72.0275 7.79666 72.0275 8.13689V10.9053C74.6683 8.24829 77.5039 6.91963 80.5351 6.91963C82.161 6.91963 83.5528 7.17484 84.7108 7.68526C84.9188 7.76395 85.1074 7.88846 85.2767 8.05835C85.4978 8.281 85.6085 8.56892 85.6085 8.92226C85.6085 9.26279 85.4914 9.55056 85.2572 9.78617C85.023 10.0216 84.7368 10.1395 84.3986 10.1395C84.1904 10.1395 84.0084 10.1002 83.8523 10.0216C82.9415 9.57694 81.8358 9.35415 80.5351 9.35415C78.1935 9.35415 76.1901 10.1921 74.5252 11.8673C72.8599 13.5297 72.0275 15.5455 72.0275 17.9146V27.6923C72.0275 28.0329 71.9105 28.3206 71.6764 28.5562C71.4422 28.7918 71.1559 28.9097 70.8178 28.9097C70.4794 28.9097 70.1934 28.7918 69.9592 28.5562C69.7251 28.3206 69.6079 28.0329 69.6079 27.6923V8.13689Z"
      fill="white"
    />
    <path
      d="M95.4043 9.51763C93.0627 9.51763 91.0592 10.3555 89.3944 12.0308C87.729 13.7063 86.8967 15.7219 86.8967 18.0779C86.8967 20.4342 87.729 22.4501 89.3944 24.1251C91.0592 25.801 93.0627 26.6385 95.4043 26.6385C97.7458 26.6385 99.749 25.801 101.414 24.1251C103.066 22.4632 103.892 20.4473 103.892 18.0779C103.892 15.709 103.066 13.6932 101.414 12.0308C99.749 10.3555 97.7458 9.51763 95.4043 9.51763ZM106.331 18.0779C106.331 21.1149 105.265 23.7065 103.131 25.8531C100.998 28 98.4221 29.073 95.4043 29.073C92.3861 29.073 89.8105 28 87.6772 25.8531C85.5437 23.7065 84.4771 21.1149 84.4771 18.0779C84.4771 15.0415 85.5437 12.4497 87.6772 10.303C89.8105 8.15641 92.3861 7.08297 95.4043 7.08297C98.4221 7.08297 100.998 8.15641 103.131 10.303C105.265 12.4497 106.331 15.0415 106.331 18.0779Z"
      fill="white"
    />
    <path
      d="M127.86 7.29496C127.449 6.93514 126.932 6.7449 126.368 6.7449C125.803 6.7449 125.283 6.93876 124.865 7.30552L124.846 7.32195C124.679 7.47751 124.542 7.65117 124.442 7.83809L117.118 22.6642L109.809 7.86628L109.793 7.83583C109.678 7.62298 109.542 7.44345 109.388 7.3019L109.352 7.26979C108.931 6.92142 108.431 6.7449 107.868 6.7449C107.293 6.7449 106.774 6.94433 106.367 7.32134C105.938 7.7181 105.711 8.2341 105.711 8.81325C105.711 9.11323 105.764 9.38638 105.867 9.62516L105.877 9.64672L115.214 28.4897C115.304 28.6802 115.44 28.8599 115.616 29.0227C116.01 29.388 116.53 29.5811 117.118 29.5811C117.728 29.5811 118.226 29.406 118.599 29.0607C118.776 28.8965 118.919 28.704 119.018 28.4981L128.35 9.6645C128.48 9.40176 128.545 9.11534 128.545 8.81325C128.545 8.21254 128.309 7.6878 127.86 7.29496Z"
      fill="white"
    />
    <path
      d="M146.422 14.6189C145.496 12.8636 144.315 11.6483 142.611 10.9966C141.283 10.4889 139.66 10.0513 137.374 10.4941C134.822 10.9888 132.815 12.9147 132.066 15.0022C131.456 16.7054 131.495 17.3211 131.463 19.1166L146.422 14.6189ZM146.866 23.1329C147.203 23.2118 147.853 23.4336 148.242 24.277C149.226 26.2451 146.545 28.4938 142.447 29.8391C139.188 30.9093 135.217 29.9698 132.675 28.1431C130.126 26.3113 128.921 24.7033 127.965 21.7653C126.853 18.3439 127.595 14.8302 128.688 12.8487C130.058 10.3671 132.154 8.04552 135.504 6.80174C138.609 5.64915 141.947 6.15007 144.862 7.65721C148.363 9.46749 150.372 12.4117 150.863 15.2422C150.977 15.8967 151.01 16.708 150.808 16.9219C150.574 17.1685 150.246 17.6661 149.909 17.7689L132.893 22.7852C134.063 24.2502 134.644 24.8313 136.177 25.4733C138.917 26.6206 140.959 26.0119 142.08 25.5937C143.231 25.1644 143.9 24.6373 144.563 24.1513C145.883 23.1847 146.084 23.0988 146.461 23.0866"
      fill="white"
    />
    <path
      d="M163.897 26.5134C161.65 26.5134 159.78 25.7314 158.181 24.1226C156.583 22.5145 155.805 20.6403 155.805 18.3927C155.805 16.1319 156.583 14.2509 158.18 12.6446C159.778 11.0482 161.648 10.2723 163.897 10.2723C166.144 10.2723 168.015 11.0483 169.612 12.6425C171.197 14.2373 171.967 16.1183 171.967 18.3927C171.967 20.6539 171.197 22.5281 169.612 24.1228C168.014 25.7314 166.144 26.5134 163.897 26.5134ZM172.456 9.80155C170.102 7.43517 167.223 6.23525 163.897 6.23525C160.569 6.23525 157.69 7.43517 155.34 9.79989C152.987 12.1531 151.793 15.0441 151.793 18.3927C151.793 21.7273 152.986 24.6177 155.34 26.9856C157.692 29.3375 160.569 30.53 163.897 30.53C166.926 30.53 169.633 29.4961 171.967 27.4518V29.9397C171.967 31.1716 171.432 32.4485 170.378 33.7357C168.62 35.4925 166.56 36.3464 164.08 36.3464C162.859 36.3464 161.708 36.1283 160.662 35.6984C159.618 35.2698 158.647 34.6079 157.777 33.7315C157.169 33.1199 156.646 32.4315 156.225 31.6847C156.107 31.4516 155.961 31.2563 155.793 31.1034C155.399 30.7163 154.908 30.5119 154.367 30.5119C153.82 30.5119 153.329 30.7151 152.946 31.0997C152.563 31.4844 152.361 31.9789 152.361 32.5304C152.361 32.9059 152.469 33.2842 152.68 33.6549C153.254 34.7051 154.014 35.6886 154.938 36.5777C157.465 39.133 160.313 40.3232 163.897 40.3232C167.573 40.3232 170.806 39.0262 173.25 36.568C175.074 34.7027 176 32.4726 176 29.9397V18.3927C176 15.0444 174.806 12.1535 172.456 9.80155Z"
      fill="white"
    />
    <path
      d="M53.9363 44.7624C53.8305 44.6536 53.7779 44.5148 53.7779 44.3463V37.377C53.7779 37.2085 53.8305 37.0699 53.9363 36.9608C54.042 36.8519 54.1764 36.7972 54.3399 36.7972C54.5032 36.7972 54.6351 36.8519 54.736 36.9608C54.8369 37.0699 54.8874 37.2085 54.8874 37.377V44.3463C54.8874 44.5148 54.8369 44.6536 54.736 44.7624C54.6351 44.8714 54.5032 44.9258 54.3399 44.9258C54.1764 44.9258 54.042 44.8714 53.9363 44.7624ZM53.7707 35.0141C53.6215 34.8558 53.5474 34.6625 53.5474 34.4346C53.5474 34.1969 53.6241 34.0036 53.7779 33.8552C53.9313 33.7064 54.1187 33.6321 54.3399 33.6321C54.551 33.6321 54.7336 33.7064 54.8874 33.8552C55.041 34.0036 55.118 34.1969 55.118 34.4346C55.118 34.6625 55.041 34.8558 54.8874 35.0141C54.7336 35.1728 54.5463 35.2519 54.3253 35.2519C54.1044 35.2519 53.9195 35.1728 53.7707 35.0141Z"
      fill="white"
    />
    <path
      d="M62.8338 37.1541C63.3764 37.4513 63.8014 37.8847 64.109 38.4543C64.4163 39.0242 64.57 39.705 64.57 40.4975V44.3613C64.57 44.5197 64.5171 44.6536 64.4116 44.7624C64.3059 44.8714 64.1761 44.9258 64.0226 44.9258C63.8591 44.9258 63.7247 44.8714 63.6191 44.7624C63.5133 44.6536 63.4606 44.5197 63.4606 44.3613V40.4975C63.4606 39.6158 63.2229 38.9447 62.7474 38.4839C62.2718 38.0233 61.6498 37.793 60.8813 37.793C60.4106 37.793 59.9856 37.8872 59.6062 38.0754C59.2264 38.2635 58.9265 38.5238 58.7056 38.8555C58.4844 39.1874 58.3743 39.5564 58.3743 39.9625V44.3613C58.3743 44.5296 58.3238 44.6657 58.2229 44.7697C58.122 44.8739 57.9899 44.9258 57.8266 44.9258C57.6632 44.9258 57.5288 44.8739 57.4232 44.7697C57.3174 44.6657 57.2646 44.5296 57.2646 44.3613V37.377C57.2646 37.2085 57.3174 37.0699 57.4232 36.9608C57.5288 36.8519 57.6632 36.7972 57.8266 36.7972C57.9899 36.7972 58.122 36.8519 58.2229 36.9608C58.3238 37.0699 58.3743 37.2085 58.3743 37.377V37.8821C58.6912 37.5158 59.0753 37.2284 59.527 37.0203C59.9784 36.8123 60.4682 36.7081 60.9966 36.7081C61.6786 36.7081 62.291 36.8568 62.8338 37.1541Z"
      fill="white"
    />
    <path
      d="M70.4203 43.9451C70.5162 44.0542 70.5645 44.1927 70.5645 44.3613C70.5645 44.5197 70.5068 44.6536 70.3914 44.7624C70.2762 44.8714 70.1321 44.9258 69.9592 44.9258H69.6566C69.1474 44.9258 68.6912 44.7995 68.2878 44.547C67.8843 44.2943 67.5696 43.9524 67.3439 43.5216C67.1182 43.0906 67.0054 42.6028 67.0054 42.0577V38.0901H66.0687C65.915 38.0901 65.7925 38.0433 65.7014 37.9491C65.61 37.855 65.5645 37.7386 65.5645 37.5998C65.5645 37.4513 65.61 37.3299 65.7014 37.2357C65.7925 37.1418 65.915 37.0945 66.0687 37.0945H67.0054V34.7913C67.0054 34.6229 67.0558 34.4844 67.1567 34.3753C67.2575 34.2664 67.3895 34.2118 67.5529 34.2118C67.7161 34.2118 67.8504 34.2664 67.9564 34.3753C68.062 34.4844 68.1149 34.6229 68.1149 34.7913V37.0945H69.743C69.8966 37.0945 70.0191 37.1418 70.1105 37.2357C70.2016 37.3299 70.2474 37.4513 70.2474 37.5998C70.2474 37.7386 70.2016 37.855 70.1105 37.9491C70.0191 38.0433 69.8966 38.0901 69.743 38.0901H68.1149V42.0577C68.1149 42.563 68.259 42.9768 68.5471 43.2985C68.8353 43.6206 69.2049 43.7817 69.6566 43.7817H70.0457C70.1992 43.7817 70.3242 43.8361 70.4203 43.9451Z"
      fill="white"
    />
    <path
      d="M73.4607 38.4469C72.9802 38.9125 72.6873 39.5266 72.5817 40.2894H77.8554C77.7784 39.5266 77.5097 38.9125 77.0485 38.4469C76.5874 37.9812 75.9967 37.7483 75.2761 37.7483C74.5461 37.7483 73.941 37.9812 73.4607 38.4469ZM77.1637 37.2356C77.7113 37.5775 78.1365 38.0507 78.4389 38.6547C78.7416 39.2593 78.8929 39.9475 78.8929 40.7202C78.8929 40.879 78.8448 41.0051 78.7488 41.0992C78.6526 41.1935 78.5276 41.2405 78.3742 41.2405H72.5528C72.6297 42.0528 72.9369 42.7117 73.4751 43.2169C74.0129 43.722 74.6855 43.9746 75.4923 43.9746C75.8382 43.9746 76.1862 43.9128 76.5369 43.7889C76.8876 43.665 77.1685 43.5092 77.3799 43.3209C77.5047 43.222 77.6343 43.1721 77.769 43.1721C77.8746 43.1721 77.9802 43.212 78.086 43.2912C78.2106 43.41 78.2733 43.5437 78.2733 43.6925C78.2733 43.8212 78.2204 43.9351 78.1147 44.0342C77.7977 44.3117 77.3944 44.5419 76.9044 44.7251C76.4145 44.9085 75.9438 45 75.4923 45C74.7237 45 74.037 44.8242 73.4318 44.4724C72.8267 44.1209 72.3534 43.6306 72.0126 43.0013C71.6715 42.3724 71.501 41.6616 71.501 40.869C71.501 40.0664 71.6617 39.3509 71.9838 38.7217C72.3055 38.0926 72.7522 37.6024 73.3237 37.2506C73.8951 36.8991 74.5461 36.7231 75.2761 36.7231C75.987 36.7231 76.6163 36.8939 77.1637 37.2356Z"
      fill="white"
    />
    <path
      d="M84.8362 36.8717C85.0715 36.9808 85.1893 37.1343 85.1893 37.3324C85.1893 37.3919 85.1843 37.4363 85.1749 37.4661C85.1364 37.6049 85.0762 37.699 84.9947 37.7484C84.9129 37.7981 84.8002 37.8227 84.6561 37.8227C84.5696 37.8227 84.4206 37.8129 84.2093 37.793C84.1325 37.7832 84.0171 37.7783 83.8637 37.7783C83.1431 37.7783 82.5547 37.9765 82.0985 38.3727C81.6421 38.769 81.414 39.2842 81.414 39.9179V44.3613C81.414 44.5394 81.3659 44.6784 81.27 44.7772C81.1738 44.8763 81.0394 44.9258 80.8666 44.9258C80.6935 44.9258 80.5588 44.8763 80.463 44.7772C80.3669 44.6784 80.3188 44.5394 80.3188 44.3613V37.362C80.3188 37.1837 80.3669 37.0452 80.463 36.946C80.5588 36.847 80.6935 36.7972 80.8666 36.7972C81.0394 36.7972 81.1738 36.847 81.27 36.946C81.3659 37.0452 81.414 37.1837 81.414 37.362V38.0158C81.6925 37.5998 82.048 37.2779 82.4804 37.05C82.9126 36.8221 83.3783 36.7081 83.878 36.7081C84.2815 36.7081 84.6007 36.7628 84.8362 36.8717Z"
      fill="white"
    />
    <path
      d="M92.0408 37.1541C92.5834 37.4513 93.0084 37.8847 93.316 38.4543C93.6233 39.0242 93.777 39.705 93.777 40.4975V44.3613C93.777 44.5197 93.7241 44.6536 93.6186 44.7624C93.5129 44.8714 93.3831 44.9258 93.2296 44.9258C93.0661 44.9258 92.9317 44.8714 92.8261 44.7624C92.7204 44.6536 92.6676 44.5197 92.6676 44.3613V40.4975C92.6676 39.6158 92.4299 38.9447 91.9544 38.4839C91.4789 38.0233 90.8567 37.793 90.0883 37.793C89.6176 37.793 89.1926 37.8872 88.8132 38.0754C88.4335 38.2635 88.1335 38.5238 87.9126 38.8555C87.6914 39.1874 87.5812 39.5564 87.5812 39.9625V44.3613C87.5812 44.5296 87.5307 44.6657 87.4299 44.7697C87.3291 44.8739 87.1969 44.9258 87.0336 44.9258C86.8702 44.9258 86.7358 44.8739 86.6302 44.7697C86.5244 44.6657 86.4717 44.5296 86.4717 44.3613V37.377C86.4717 37.2085 86.5244 37.0699 86.6302 36.9608C86.7358 36.8519 86.8702 36.7972 87.0336 36.7972C87.1969 36.7972 87.3291 36.8519 87.4299 36.9608C87.5307 37.0699 87.5812 37.2085 87.5812 37.377V37.8821C87.8982 37.5158 88.2823 37.2284 88.7338 37.0203C89.1854 36.8123 89.6753 36.7081 90.2037 36.7081C90.8856 36.7081 91.498 36.8568 92.0408 37.1541Z"
      fill="white"
    />
    <path
      d="M100.852 43.5586C101.294 43.2912 101.642 42.9221 101.897 42.4515C102.151 41.9812 102.279 41.4536 102.279 40.869C102.279 40.2844 102.151 39.757 101.897 39.2863C101.642 38.8159 101.294 38.4445 100.852 38.1719C100.41 37.8995 99.9154 37.7631 99.368 37.7631C98.8204 37.7631 98.3233 37.8995 97.8766 38.1719C97.4299 38.4445 97.0791 38.8159 96.8246 39.2863C96.5701 39.757 96.4429 40.2844 96.4429 40.869C96.4429 41.4536 96.5701 41.9812 96.8246 42.4515C97.0791 42.9221 97.4299 43.2912 97.8766 43.5586C98.3233 43.826 98.8204 43.9599 99.368 43.9599C99.9154 43.9599 100.41 43.826 100.852 43.5586ZM101.407 37.2655C102.017 37.6271 102.497 38.1249 102.848 38.7589C103.198 39.3929 103.374 40.0963 103.374 40.869V44.3612C103.374 44.5196 103.321 44.6535 103.215 44.7623C103.109 44.8713 102.975 44.9257 102.812 44.9257C102.648 44.9257 102.514 44.8738 102.408 44.7696C102.302 44.6657 102.25 44.5295 102.25 44.3612V43.4695C101.913 43.945 101.486 44.3191 100.967 44.5913C100.449 44.864 99.8769 45 99.2526 45C98.5224 45 97.8622 44.8194 97.2714 44.4576C96.6807 44.0961 96.2146 43.6008 95.8738 42.9716C95.5326 42.3427 95.3623 41.6416 95.3623 40.869C95.3623 40.0963 95.5374 39.3929 95.8881 38.7589C96.2387 38.1249 96.7189 37.6271 97.329 37.2655C97.9389 36.9038 98.6186 36.7231 99.368 36.7231C100.117 36.7231 100.797 36.9038 101.407 37.2655Z"
      fill="white"
    />
    <path
      d="M108.935 43.9451C109.031 44.0542 109.079 44.1927 109.079 44.3613C109.079 44.5197 109.022 44.6536 108.906 44.7624C108.791 44.8714 108.647 44.9258 108.474 44.9258H108.171C107.662 44.9258 107.206 44.7995 106.802 44.547C106.399 44.2943 106.084 43.9524 105.859 43.5216C105.633 43.0906 105.52 42.6028 105.52 42.0577V38.0901H104.584C104.43 38.0901 104.307 38.0433 104.216 37.9491C104.125 37.855 104.079 37.7386 104.079 37.5998C104.079 37.4513 104.125 37.3299 104.216 37.2357C104.307 37.1418 104.43 37.0945 104.584 37.0945H105.52V34.7913C105.52 34.6229 105.57 34.4844 105.671 34.3753C105.772 34.2664 105.904 34.2118 106.068 34.2118C106.231 34.2118 106.365 34.2664 106.471 34.3753C106.577 34.4844 106.63 34.6229 106.63 34.7913V37.0945H108.258C108.411 37.0945 108.534 37.1418 108.625 37.2357C108.716 37.3299 108.762 37.4513 108.762 37.5998C108.762 37.7386 108.716 37.855 108.625 37.9491C108.534 38.0433 108.411 38.0901 108.258 38.0901H106.63V42.0577C106.63 42.563 106.774 42.9768 107.062 43.2985C107.35 43.6206 107.72 43.7817 108.171 43.7817H108.56C108.714 43.7817 108.839 43.8361 108.935 43.9451Z"
      fill="white"
    />
    <path
      d="M110.823 44.7624C110.717 44.6536 110.664 44.5148 110.664 44.3463V37.377C110.664 37.2085 110.717 37.0699 110.823 36.9608C110.928 36.8519 111.062 36.7972 111.226 36.7972C111.389 36.7972 111.521 36.8519 111.622 36.9608C111.723 37.0699 111.774 37.2085 111.774 37.377V44.3463C111.774 44.5148 111.723 44.6536 111.622 44.7624C111.521 44.8714 111.389 44.9258 111.226 44.9258C111.062 44.9258 110.928 44.8714 110.823 44.7624ZM110.657 35.0141C110.508 34.8558 110.434 34.6625 110.434 34.4346C110.434 34.1969 110.51 34.0036 110.664 33.8552C110.818 33.7064 111.005 33.6321 111.226 33.6321C111.437 33.6321 111.62 33.7064 111.774 33.8552C111.927 34.0036 112.004 34.1969 112.004 34.4346C112.004 34.6625 111.927 34.8558 111.774 35.0141C111.62 35.1728 111.433 35.2519 111.212 35.2519C110.99 35.2519 110.806 35.1728 110.657 35.0141Z"
      fill="white"
    />
    <path
      d="M119.237 43.5586C119.679 43.2912 120.025 42.9221 120.275 42.4515C120.524 41.9812 120.649 41.4536 120.649 40.869C120.649 40.2745 120.524 39.742 120.275 39.2714C120.025 38.801 119.679 38.4319 119.237 38.1644C118.795 37.897 118.296 37.7631 117.739 37.7631C117.191 37.7631 116.694 37.897 116.247 38.1644C115.801 38.4319 115.45 38.801 115.196 39.2714C114.941 39.742 114.814 40.2745 114.814 40.869C114.814 41.4536 114.941 41.9812 115.196 42.4515C115.45 42.9221 115.801 43.2912 116.247 43.5586C116.694 43.826 117.191 43.9599 117.739 43.9599C118.296 43.9599 118.795 43.826 119.237 43.5586ZM115.685 44.465C115.075 44.1085 114.597 43.6156 114.252 42.9865C113.906 42.3574 113.733 41.6517 113.733 40.869C113.733 40.0865 113.906 39.378 114.252 38.744C114.597 38.1101 115.075 37.6146 115.685 37.2579C116.295 36.9013 116.98 36.7231 117.739 36.7231C118.497 36.7231 119.18 36.9013 119.785 37.2579C120.39 37.6146 120.868 38.1101 121.218 38.744C121.569 39.378 121.744 40.0865 121.744 40.869C121.744 41.6517 121.572 42.3574 121.226 42.9865C120.88 43.6156 120.402 44.1085 119.792 44.465C119.182 44.8218 118.497 45 117.739 45C116.98 45 116.295 44.8218 115.685 44.465Z"
      fill="white"
    />
    <path
      d="M129.028 37.1541C129.571 37.4513 129.996 37.8847 130.303 38.4543C130.61 39.0242 130.764 39.705 130.764 40.4975V44.3613C130.764 44.5197 130.711 44.6536 130.606 44.7624C130.5 44.8714 130.37 44.9258 130.217 44.9258C130.053 44.9258 129.919 44.8714 129.813 44.7624C129.707 44.6536 129.655 44.5197 129.655 44.3613V40.4975C129.655 39.6158 129.417 38.9447 128.942 38.4839C128.466 38.0233 127.844 37.793 127.076 37.793C126.605 37.793 126.18 37.8872 125.8 38.0754C125.421 38.2635 125.121 38.5238 124.9 38.8555C124.679 39.1874 124.568 39.5564 124.568 39.9625V44.3613C124.568 44.5296 124.518 44.6657 124.417 44.7697C124.316 44.8739 124.184 44.9258 124.021 44.9258C123.857 44.9258 123.723 44.8739 123.617 44.7697C123.512 44.6657 123.459 44.5296 123.459 44.3613V37.377C123.459 37.2085 123.512 37.0699 123.617 36.9608C123.723 36.8519 123.857 36.7972 124.021 36.7972C124.184 36.7972 124.316 36.8519 124.417 36.9608C124.518 37.0699 124.568 37.2085 124.568 37.377V37.8821C124.885 37.5158 125.27 37.2284 125.721 37.0203C126.173 36.8123 126.662 36.7081 127.191 36.7081C127.873 36.7081 128.485 36.8568 129.028 37.1541Z"
      fill="white"
    />
    <path
      d="M137.839 43.5586C138.281 43.2912 138.629 42.9221 138.884 42.4515C139.138 41.9812 139.266 41.4536 139.266 40.869C139.266 40.2844 139.138 39.757 138.884 39.2863C138.629 38.8159 138.281 38.4445 137.839 38.1719C137.397 37.8995 136.903 37.7631 136.355 37.7631C135.807 37.7631 135.31 37.8995 134.864 38.1719C134.417 38.4445 134.066 38.8159 133.812 39.2863C133.557 39.757 133.43 40.2844 133.43 40.869C133.43 41.4536 133.557 41.9812 133.812 42.4515C134.066 42.9221 134.417 43.2912 134.864 43.5586C135.31 43.826 135.807 43.9599 136.355 43.9599C136.903 43.9599 137.397 43.826 137.839 43.5586ZM138.394 37.2655C139.004 37.6271 139.484 38.1249 139.835 38.7589C140.185 39.3929 140.361 40.0963 140.361 40.869V44.3612C140.361 44.5196 140.308 44.6535 140.202 44.7623C140.096 44.8713 139.962 44.9257 139.799 44.9257C139.635 44.9257 139.501 44.8738 139.395 44.7696C139.289 44.6657 139.237 44.5295 139.237 44.3612V43.4695C138.9 43.945 138.473 44.3191 137.954 44.5913C137.436 44.864 136.864 45 136.24 45C135.509 45 134.849 44.8194 134.258 44.4576C133.668 44.0961 133.202 43.6008 132.861 42.9716C132.519 42.3427 132.349 41.6416 132.349 40.869C132.349 40.0963 132.524 39.3929 132.875 38.7589C133.226 38.1249 133.706 37.6271 134.316 37.2655C134.926 36.9038 135.606 36.7231 136.355 36.7231C137.104 36.7231 137.784 36.9038 138.394 37.2655Z"
      fill="white"
    />
    <path
      d="M142.342 44.2051C141.972 43.7247 141.787 43.108 141.787 42.355V33.8849C141.787 33.7165 141.84 33.5802 141.946 33.4762C142.051 33.3722 142.181 33.3202 142.335 33.3202C142.498 33.3202 142.63 33.3722 142.731 33.4762C142.832 33.5802 142.882 33.7165 142.882 33.8849V42.355C142.882 42.781 142.966 43.1277 143.134 43.3953C143.302 43.6627 143.516 43.7964 143.775 43.7964H144.136C144.28 43.7964 144.395 43.8484 144.481 43.9524C144.568 44.0564 144.611 44.1927 144.611 44.3613C144.611 44.5296 144.551 44.6657 144.431 44.7697C144.311 44.8739 144.155 44.9258 143.963 44.9258H143.775C143.189 44.9258 142.712 44.6858 142.342 44.2051Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.8012 11.3483C5.68825 11.2211 5.56361 11.082 5.45113 10.9448C5.34463 10.8176 5.19838 10.6619 5.21699 10.4754C5.92767 10.487 6.53558 10.8531 7.08673 11.1804C7.94054 11.6889 10.959 14.0771 13.8796 19.621C16.5074 24.6124 18.5777 31.497 18.8421 32.4651C19.024 33.1223 19.1793 33.8006 19.526 34.3752C19.526 34.3752 19.5323 34.3756 19.5531 34.3431C19.6632 34.1715 19.7769 33.9156 19.8614 33.6903C20.1745 32.8548 21.1986 28.5211 22.905 24.3278C22.9154 24.3022 22.9252 24.277 22.9369 24.2514C23.673 22.2872 24.5139 20.2752 25.3977 18.594C26.9227 15.7008 28.563 13.6024 29.9088 12.1788C29.6983 12.0938 29.4676 12.1887 29.2788 12.2516C27.8825 12.7246 26.9088 13.7161 26.0398 14.7059C25.2935 15.5532 24.5522 16.4956 24.0201 17.5599C23.8398 17.9209 23.739 18.3245 23.4845 18.6516C23.3263 18.5176 23.2974 18.2685 23.2689 18.065C23.0111 16.27 23.5333 14.5634 24.0688 13.3784C25.0325 11.2464 26.6631 9.51243 29.0938 8.82429C29.9173 8.59229 30.7949 8.41246 31.6827 8.23639C32.9056 7.99369 34.1233 7.73969 35.3249 7.42659C31.6818 2.89735 26.114 6.10352e-05 19.8721 6.10352e-05C8.89706 6.10352e-05 0 8.95242 0 19.9956C0 30.2743 7.70827 38.7401 17.6253 39.8633C17.1213 37.4317 15.8096 31.134 13.7433 25.2056C11.6128 19.1006 9.34193 15.3684 5.8012 11.3483Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.6297 9.24412C36.6233 9.27231 36.6156 9.29899 36.6095 9.32748C36.2762 10.8583 36.3232 12.9024 35.941 14.3685C35.7505 15.1005 35.4155 15.7076 34.9894 16.2516C33.7208 17.863 32.0921 18.9938 29.7794 19.5613C29.0314 19.7445 28.2176 19.7953 27.4751 20.082C27.303 20.1462 27.1145 20.3597 26.946 20.5971C26.4527 21.7123 25.9827 22.8971 25.5364 24.1784C23.6127 29.6937 22.2332 36.1817 21.6165 39.1886C21.577 39.463 21.5422 39.7079 21.5112 39.9229C31.7195 39.0841 39.7443 30.4833 39.7443 19.9955C39.7443 16.0378 38.6013 12.3488 36.6297 9.24412Z"
      fill="white"
    />
  </svg>
);
