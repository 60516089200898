import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import { Row, Col } from 'react-bootstrap';
import { CalculatorType } from '@proveg/dtos';
import { ProvegFormState } from '../../interface/ProvegFormState';
import { RadioButton } from '../inputs/RadioButton';
import { Section } from '../layout/Section';

const getCalculatorDescription = (type: CalculatorType) => {
  switch (type) {
    case CalculatorType.DIET:
      return 'This calculator allows you to determine the impact of transitioning towards a fully plant-based diet.';
    case CalculatorType.INGREDIENTS:
      return 'This calculator allows you to determine the impact of replacing ingredients such as eggs, egg yolks or meat, including different cuts of meat.';
    case CalculatorType.MEALS:
      return 'This calculator allows you to determine the impact of replacing meals. By default, we assume that every replaced meal contained about 100g of meat. It is possible to further specify the kind of meat in order to get more specific results.';
    default:
      return '';
  }
};

export const CalculatorTypeSection: FC = () => {
  const formContext = useFormikContext<ProvegFormState>();

  const calculatorDescription = getCalculatorDescription(formContext.values.calculator);

  return (
    <Section>
      <Row>
        <Col className="font-condensed">Which calculator do you want to use?</Col>
      </Row>
      <Row className="py-3">
        <Col className="w-100 d-flex flex-md-nowrap">
          <RadioButton name="calculator" value="ingredients" className="flex-basis-33 mx-md-2">
            Ingredients calculator
          </RadioButton>

          <RadioButton name="calculator" value="meals" className="flex-basis-33 mx-md-2">
            Meals calculator
          </RadioButton>

          <RadioButton name="calculator" value="diet" className="flex-basis-33 mx-md-2">
            Diet calculator
          </RadioButton>
        </Col>
      </Row>
      {calculatorDescription && (
        <Row>
          <Col className="me-md-5 my-4 mb-sm-0">{calculatorDescription}</Col>
        </Row>
      )}
    </Section>
  );
};
