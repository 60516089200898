import { useFormikContext } from 'formik';
import React, { FC, useEffect } from 'react';
import { Col } from 'react-bootstrap';

import { useImpactCalculation } from 'apps/calculator/src/providers/CalculationProvider';
import { CalculatorType, FoodType } from '@proveg/dtos';
import useAvailableItemDetail from 'apps/calculator/src/hooks/useAvailableItemDetail';
import useAxiosQuery from 'apps/calculator/src/hooks/useAxiosQuery';

import { Dropdown } from '../../inputs/Dropdown';
import { NumberValueInput } from '../../inputs/NumberValueInput';
import { ResultSection } from '../../sections/ResultSection';
import { Pig } from '../../svg/Pig';
import { AVAILABLE_TYPES } from 'apps/calculator/src/interface/availableTypes';
import { ProvegFormState } from 'apps/calculator/src/interface/ProvegFormState';
import { SVGProps } from '../../svg/SVGProps';
import { Chicken } from '../../svg/Chicken';
import { Cow } from '../../svg/Cow';
import { AnimalGroup } from '../../svg/AnimalGroup';

export const MealCalculator: FC = () => {
  const { values, setFieldValue } = useFormikContext<ProvegFormState>();

  const subcategoriesQuery = useAxiosQuery<string[]>('item-details', {
    params: { item: values.item },
    queryOptions: { enabled: values.item !== FoodType.MEAT },
  });

  useEffect(() => {
    if ([FoodType.MILK, FoodType.EGGS].includes(values.item)) {
      setFieldValue('item', FoodType.MEAT);
    }
  }, [values.item, setFieldValue]);

  useAvailableItemDetail(subcategoriesQuery, values.item);

  const calculatedImpact = useImpactCalculation();

  const AnimalSvg: FC<SVGProps> = (props) => {
    switch (values.item) {
      case FoodType.PIG_MEAT:
        return <Pig {...props} />;
      case FoodType.POULTRY_MEAT:
        return <Chicken {...props} />;
      case FoodType.BOVINE_MEAT:
        return <Cow {...props} />;
      default:
        return <AnimalGroup {...props} />;
    }
  };

  return (
    <ResultSection>
      <Col className="col-12 col-md-5">
        <NumberValueInput
          className="mb-4"
          name="amount"
          label="How many meals do you want to replace?"
        />

        <Dropdown
          name="item"
          className="mb-4"
          label="Please choose the type of meat (optional)"
          options={AVAILABLE_TYPES[CalculatorType.MEALS].map((d) => ({ value: d, label: d })) ?? []}
        />

        <Dropdown
          name="item_detail"
          isDisabled={values.item === FoodType.MEAT}
          className="mb-4"
          label="Please choose a subcategory (optional)"
          options={subcategoriesQuery.data?.map((d) => ({ value: d, label: d })) ?? []}
          isLoading={subcategoriesQuery.isLoading}
        />
        <label className="mb-4 mb-sm-0">
          Please note: when combining different cuts from the same animal type results may be less
          accurate, since each cut will be calculated as coming from a new animal
        </label>
      </Col>

      <Col className="col-12 col-md-7 d-flex flex-column">
        <label className="fw-bold mb-3 ">
          The amount of meals replaced avoids the consumption of
        </label>

        <div className="d-sm-flex justify-content-evenly align-items-center mt-sm-3 flex-grow-1">
          <div className="d-flex justify-content-center">
            <AnimalSvg text={calculatedImpact.value} />
          </div>
          <label className="fw-bold">animals</label>
        </div>
      </Col>
    </ResultSection>
  );
};
