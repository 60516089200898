import React, { FC } from 'react';
import axios from 'axios';
import { Formik } from 'formik';
import { Container, Form, Button, Row, Col, Spinner, Fade, Alert } from 'react-bootstrap';
import * as yup from 'yup';
import { DataUploadDTO } from '@proveg/dtos';
import { useAlert } from '../hooks/useAlert';

const validationSchema = yup.object().shape({
  username: yup.string().required('Username is required'),
  password: yup.string().required('Password is required'),
  file: yup.mixed().required('CSV File is required'),
});

export const DataUploader: FC = () => {
  const { activateAlert, message, closeAlert, variant } = useAlert();

  return (
    <Container>
      <Formik<DataUploadDTO>
        onSubmit={async (values, helpers) => {
          if (!values.file) return;

          const formData = new FormData();
          formData.append('username', values.username);
          formData.append('password', values.password);
          formData.append('file', values.file);

          try {
            await axios.post('upload', formData, {
              headers: { 'content-type': 'multipart/form-data' },
            });
            activateAlert('Upload successful!', 'success');
          } catch (err) {
            activateAlert(err.response.data.message, 'danger');
          }

          helpers.setFieldValue('file', undefined, false);
          helpers.setFieldValue('password', '', false);
        }}
        validationSchema={validationSchema}
        initialValues={{ username: '', password: '', file: undefined }}
      >
        {({ handleSubmit, handleChange, values, errors, touched, isSubmitting, setFieldValue }) => (
          <Row>
            <Col className="col-md-6 m-auto">
              <Form onSubmit={handleSubmit}>
                <div className="my-3">
                  <label className="fw-bold mb-2">Username</label>
                  <Form.Control
                    onChange={handleChange}
                    value={values.username}
                    name="username"
                    className="p-text-input rounded-0 shadow-sm"
                    placeholder="Enter username..."
                  />
                  <Fade in={!!(errors.username && touched.username)}>
                    <label className="text-danger mt-2">{errors.username}</label>
                  </Fade>
                </div>
                <div className="my-3">
                  <label className="fw-bold mb-2">Password</label>
                  <Form.Control
                    onChange={handleChange}
                    value={values.password}
                    name="password"
                    type="password"
                    className="p-text-input rounded-0 shadow-sm"
                    placeholder="Enter password..."
                  />
                  <Fade in={!!(errors.password && touched.password)}>
                    <label className="text-danger mt-2">{errors.password}</label>
                  </Fade>
                </div>
                <Fade in={!!values.file}>
                  <label className="my-3 fw-bold d-flex align-items-center">
                    {values.file?.name}
                  </label>
                </Fade>
                <div className="d-flex justify-content-between">
                  {values.file ? (
                    <Button
                      variant="outline-danger"
                      className="p-text-input rounded-0 shadow-sm"
                      onClick={() => setFieldValue('file', undefined)}
                    >
                      Remove file
                    </Button>
                  ) : (
                    <Button
                      as="label"
                      variant="outline-primary"
                      className="rounded-0 shadow-sm d-flex justify-content-center align-items-center"
                    >
                      <Form.Control
                        as="input"
                        accept=".csv"
                        onChange={(event) => {
                          setFieldValue('file', (event.currentTarget as any).files[0]);
                        }}
                        name="file"
                        type="file"
                        className="d-none"
                      />
                      Select .csv file
                    </Button>
                  )}
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    style={{ width: 120 }}
                    className="p-text-input rounded-0 shadow-sm"
                  >
                    {isSubmitting ? <Spinner animation="grow" /> : 'Submit'}
                  </Button>
                </div>
                <Fade in={!!(touched.file && errors.file)}>
                  <label className="text-danger mt-2">{errors.file}</label>
                </Fade>
              </Form>
            </Col>
          </Row>
        )}
      </Formik>
      <Fade in={!!message}>
        <Alert
          className="w-50 m-auto"
          variant={variant}
          dismissible={!!message}
          onClose={closeAlert}
        >
          {message}
        </Alert>
      </Fade>
    </Container>
  );
};
