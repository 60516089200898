import React, { FC } from 'react';
import { CalculatorType, FoodType } from '@proveg/dtos';
import { BovineMeatIngredientCalculator } from './ingredients/BovineMeatIngredientCalculator';
import { EggsIngredientCalculator } from './ingredients/EggsIngredientCalculator';
import { MilkIngredientCalculator } from './ingredients/MilkIngredientCalculator';
import { PigMeatIngredientCalculator } from './ingredients/PigMeatIngredientCalculator';
import { PoultryMeatIngredientCalculator } from './ingredients/PoultryMeatIngredientCalculator';
import { MealCalculator } from './meals/MealCalculator';
import { DietCalculator } from './diet/DietCalculator';

interface CalculatorControllerProps {
  foodType: FoodType;
  calculatorType: CalculatorType;
}

export const CalculatorController: FC<CalculatorControllerProps> = ({
  foodType,
  calculatorType,
}) => {
  if (calculatorType === CalculatorType.INGREDIENTS) {
    switch (foodType) {
      case FoodType.EGGS:
        return <EggsIngredientCalculator />;
      case FoodType.MILK:
        return <MilkIngredientCalculator />;
      case FoodType.BOVINE_MEAT:
        return <BovineMeatIngredientCalculator />;
      case FoodType.POULTRY_MEAT:
        return <PoultryMeatIngredientCalculator />;
      case FoodType.PIG_MEAT:
        return <PigMeatIngredientCalculator />;
    }
  } else if (calculatorType === CalculatorType.MEALS) {
    return <MealCalculator />;
  } else {
    return <DietCalculator />;
  }
  return null;
};
