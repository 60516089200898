import React, { FC } from 'react';
import { Row, Col } from 'react-bootstrap';
import classNames from 'classnames';
import { CalculatorType } from '@proveg/dtos';
import { AVAILABLE_TYPES } from '../../interface/availableTypes';
import { RadioButton } from '../inputs/RadioButton';
import { Section } from '../layout/Section';

interface FoodTypeSectionProps {
  calculatorType: CalculatorType;
}

export const FoodTypeSection: FC<FoodTypeSectionProps> = ({ calculatorType }) => {
  const availableTypes = AVAILABLE_TYPES[calculatorType];

  if (calculatorType !== CalculatorType.INGREDIENTS) {
    return null;
  }

  return (
    <Section>
      <Row>
        <Col className="font-condensed">Which type of ingredients do you want to replace?</Col>
      </Row>
      <Row className="py-3">
        <Col className="w-100 d-flex flex-wrap flex-md-nowrap">
          {availableTypes.map((type) => (
            <RadioButton
              name="item"
              key={type}
              value={type}
              className={classNames(
                'flex-grow-1 mx-md-2',
                availableTypes.length % 2 === 0 ? 'flex-basis-50' : 'flex-basis-33'
              )}
            >
              {type}
            </RadioButton>
          ))}
        </Col>
      </Row>
    </Section>
  );
};
