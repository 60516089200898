import { CalculatorType, FoodType } from '@proveg/dtos';

export const AVAILABLE_TYPES = {
  [CalculatorType.INGREDIENTS]: [
    FoodType.EGGS,
    FoodType.MILK,
    FoodType.POULTRY_MEAT,
    FoodType.BOVINE_MEAT,
    FoodType.PIG_MEAT,
  ],
  [CalculatorType.MEALS]: [
    FoodType.MEAT,
    FoodType.POULTRY_MEAT,
    FoodType.BOVINE_MEAT,
    FoodType.PIG_MEAT,
  ],
  [CalculatorType.DIET]: [FoodType.MEAT, FoodType.MILK, FoodType.EGGS],
};
