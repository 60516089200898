import React, { FC } from 'react';
import { Button } from 'react-bootstrap';
import { useField, Field } from 'formik';
import classNames from 'classnames';
import { useBreakpointValue } from '../../hooks/useBreakpointValue';

interface RadioButtonProps {
  name: string;
  value: string;
  className?: string;
  disabled?: boolean;
}

export const RadioButton: FC<RadioButtonProps> = ({
  name,
  value,
  className,
  disabled,
  children,
}) => {
  const fontSize = useBreakpointValue({
    base: 'fs-7',
    sm: 'fs-5',
  });
  const [field] = useField(name);

  const isActive = field.value === value;

  return (
    <Button
      as="label"
      variant={isActive ? 'primary' : 'outline-primary'}
      active={isActive}
      disabled={disabled}
      className={classNames(
        'd-flex justify-content-center align-items-center font-condensed',
        'p-button rounded-0 text-wrap px-2 py-1 px-md-3 py-md-2',
        isActive ? 'shadow' : 'border-lightgray',
        fontSize,
        className
      )}
    >
      <Field
        disabled={disabled}
        className="hidden-radio-input"
        type="radio"
        name={name}
        value={value}
      />
      {children}
    </Button>
  );
};
