import React, { FC } from 'react';
import { Container } from 'react-bootstrap';
import classNames from 'classnames';
import { useBreakpointValue } from '../../hooks/useBreakpointValue';

interface SectionProps {
  className?: string;
}

export const Section: FC<SectionProps> = ({ children, className }) => {
  const border = useBreakpointValue({ sm: 'border border-bordergreen' });
  return (
    <Container className={classNames('w-100 mt-3 mt-sm-5 p-sm-5', border, className)}>
      {children}
    </Container>
  );
};
