import Axios from 'axios';

const initAxios = () => {
  Axios.defaults.headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  Axios.defaults.baseURL = process.env.REACT_APP_API_ROOT || '/api';
};

export default initAxios;
